<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczllxglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="24" style="height: 100%;">
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" @tableColumnClick="tableColumnClick" :tableTotal="tableTotal"></basic-table >
          </div>
        </el-col>
      </el-row>
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform" @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 编辑弹窗 -->
    <general-form :FormDialog="FormDialogEdit" :FormList="FormList" :title="EditTitle" :FormData="modifyForm" @cancelDialog="cancelDialogEdit" @confirmDialog="confirmDialogEdit"></general-form>
    

  </div>

</template>

<script>
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      //校验
      var checkName = (rule, value, callback) => {
        var len = 0;  
        for (var i=0; i<value.length; i++) {   
          var c = value.charCodeAt(i);   
          //单字节加1   
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {   
            len++;   
          } else {   
            len+=2;
          }   
        };   
        if (len > 40) {
          //重点重点，下面就是填写提示的文字
          callback(new Error('类型名称长度为40个字符，一个中文字等于2个字符。'));
        } else {
          callback();
        }
      };
      return {
        loading:false,//遮罩
        jsonData:[//table数据
          {serialNumber:1,typeName:'DN15',manufacturer:'-',specifications:'-',hourFlow:'8.4',manufacturerAddress:'-',digitalDisplayDigit:'-',displayNum:'0',displayConfigure:'',remark:'',}
        ],
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          key: undefined
        },
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加住户弹窗
        addform:{},//添加表单
        modifyForm:{},//编辑表单
        FormList:{//表格列
          column:[
            {columnKey:'serialNumber',columnDescription:'序号',columnType:'num',prop:'serialNumber',width:'120',placeholder:"序号",},
            {columnKey:'typeName',columnDescription:'类型名称',columnType:'text',prop:'typeName',width:'120',placeholder:"类型名称",},
            {columnKey:'manufacturer',columnDescription:'生产厂家',columnType:'text',prop:'manufacturer',width:'120',placeholder:"生产厂家",},
            {columnKey:'specifications',columnDescription:'规格',columnType:'text',prop:'specifications',width:'120',placeholder:"规格",},
            {columnKey:'hourFlow',columnDescription:'小时流量',columnType:'num',prop:'hourFlow',width:'120',placeholder:"小时流量",},
            {columnKey:'manufacturerAddress',columnDescription:'厂家地址',columnType:'text',prop:'manufacturerAddress',width:'120',placeholder:"厂家地址",},
            {columnKey:'digitalDisplayDigit',columnDescription:'显示位数',columnType:'num',prop:'digitalDisplayDigit',width:'120',placeholder:"显示位数",},
            {columnKey:'displayNum',columnDescription:'显示序号',columnType:'num',prop:'displayNum',width:'120',placeholder:"显示序号",},
            {columnKey:'displayConfigure',columnDescription:'显示配置',columnType:'text',prop:'displayConfigure',width:'120',placeholder:"显示配置",},
            {columnKey:'remark',columnDescription:'备注',columnType:'text',prop:'remark',width:'120',placeholder:"备注",},
          ],
          //校验
          rule:{
            typeName: [{ required: true, message: "类型名称不能为空", trigger: "blur" }, {validator: checkName,trigger: 'blur' }],
          }
        },
        addTitle:'添加',//添加弹窗标题
        FormDialogEdit:false,//编辑表弹窗
        EditTitle:'编辑',//添加弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
      }
    },
    watch: {

    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.tableChooseRow.id){
              this.handleDelete(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnEdit'://编辑
            if(this.tableChooseRow.id){
              this.modify(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.lxglGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //点击弹窗
      tableColumnClick(row){
        this.addform = row
        this.FormDialogEdit = true
      },
      // 多行删除
      handleDelete(row) {
        this.$confirm(`确定要删除序号为${row.serialNumber}的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = [row.id]
          basicDataApi.lxglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.reset()//重置
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add添加取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add添加提交
      confirmDialog(){
        basicDataApi.lxglAdd(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialog = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialog = false
        })
        this.reset()//重置
      },
      //重置
      reset(){
        this.addform = this.modifyForm ={
          serialNumber:'',
          typeName:'',
          manufacturer:'',
          specifications:'',
          hourFlow:'',
          manufacturerAddress:'',
          digitalDisplayDigit:'',
          displayNum:'',
          displayConfigure:'',
          remark:'',
        }
        this.tableChooseRow = {}
      },
      // 编辑表
      modify(row){
        this.modifyForm = JSON.parse(JSON.stringify(row))
        this.FormDialogEdit = true
      },
      // 编辑取消
      cancelDialogEdit(val){
        this.FormDialogEdit = val
      },
      // 编辑提交
      confirmDialogEdit(){
        basicDataApi.lxglModify(this.modifyForm).then(response => {
          if(response.code == 200){
            this.FormDialogEdit = false
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogEdit = false
        })
      },
    },
  }

</script>

<style lang="scss" scoped>
  .add_class .el-form-item{
    width: 50%;
    float: left;
  }
</style>
